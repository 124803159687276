<template>
  <date-range-picker
    placement="right"
    :locale="locale"
    :model-value="computedRange"
    css-classes="timetracking-popper"
    @update:modelValue="setDates"
  />
</template>

<script>
import DateRangePicker from '@/components/date-picker/range'

export default {
  components: {
    DateRangePicker
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({ start: undefined, end: undefined })
    }
  },
  data() {
    return {
      locale: this.$i18n.locale
    }
  },
  computed: {
    computedRange() {
      const showDateText = this.modelValue.start && this.modelValue.end
      return { ...this.modelValue, showDateText }
    }
  },
  methods: {
    setDates(dates) {
      const start = dates.start
        ? new Date(dates.start).toISOString(this.locale)
        : undefined
      const end = dates.end
        ? new Date(dates.end).toISOString(this.locale)
        : undefined
      this.$emit('update:modelValue', { start, end })
    }
  }
}
</script>

<style scoped>
.time-period {
  box-shadow: 2px 2px 4px 1px lightgray;
  width: 17rem;
}
</style>
<style>
.timetracking-popper {
  margin-left: -150px !important;
}
</style>
