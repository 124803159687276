<template>
  <th-modal
    name="timetrack-entry-create"
    width="800px"
    :title="$t('pages.timetracking.report.entries.create.title')"
    data-testid="timetracking-entry-create"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row type="flex">
        <!-- Date -->
        <el-form-item
          prop="date"
          for="timetracking-date"
          :label="$t('pages.timetracking.report.entries.create.date')"
        >
          <date-picker-input
            id="timetracking-date"
            v-model="form.date"
            class="py-2"
            no-future-dates
            :placeholder="$t('pages.staff.form.placeholder.select_date')"
          />
        </el-form-item>
      </el-row>

      <el-row :gutter="20" class="w-full">
        <!-- Clock In -->
        <el-col :span="8">
          <el-form-item
            prop="day.started_at"
            for="day.started_at"
            :label="$t('pages.timetracking.report.entries.clock_in')"
            :rules="timeRules.concat(timeRequired)"
          >
            <time-input
              id="day.started_at"
              v-model="form.day.started_at"
              hide-warning-icon
              :disabled="!hasDate"
            />
          </el-form-item>
        </el-col>

        <!-- Clock Out -->
        <el-col :span="8">
          <el-form-item
            prop="day.ended_at"
            for="day.ended_at"
            :label="$t('pages.timetracking.report.entries.clock_out')"
            :rules="timeRules.concat(timeEndingRules).concat(timeRequired)"
          >
            <time-input
              id="day.ended_at"
              v-model="form.day.ended_at"
              allow-empty
              hide-warning-icon
              :disabled="!form.day.started_at || !hasDate"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="w-full">
        <!-- Break start -->
        <el-col :span="8">
          <th-input-title
            :title="$t('pages.timetracking.report.entries.break_start')"
          />
        </el-col>
        <!-- Break end -->
        <el-col :span="8">
          <th-input-title
            :title="$t('pages.timetracking.report.entries.break_end')"
          />
        </el-col>
      </el-row>

      <el-row
        v-for="(entry, index) in form.breaks"
        :key="entry.id"
        class="mb-2 last:mb-0 w-full"
      >
        <el-row :gutter="20" class="w-full">
          <el-col :span="8">
            <el-form-item
              :rules="timeRules"
              :prop="`breaks.${index}.started_at`"
              class="mb-0"
            >
              <time-input
                v-model="entry.started_at"
                hide-warning-icon
                :disabled="!hasClockInOut"
                :class="{
                  'validation-error': currentOverlap === entry.row_id
                }"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              :rules="timeRules.concat(timeEndingRules)"
              :prop="`breaks.${index}.ended_at`"
              class="mb-0"
            >
              <time-input
                v-model="entry.ended_at"
                :allow-empty="true"
                hide-warning-icon
                :disabled="!entry.started_at || !hasClockInOut"
                :class="{
                  'validation-error': currentOverlap === entry.row_id
                }"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <!-- Delete break -->
            <el-button
              v-permissions="{ scopes: ['timetracking:delete'] }"
              :disabled="!entry.started_at"
              plain
              icon="Delete"
              class="-ml-3 el-button--text-icon"
              @click="handleDeleteBreak(index)"
            />

            <!-- Add break -->
            <el-button
              v-if="index === form.breaks.length - 1"
              :disabled="!isLastEmpty"
              icon="Plus"
              class="el-button--primary-icon"
              plain
              @click="addEmptyBreak"
            />
          </el-col>
        </el-row>

        <el-col v-show="currentOverlap === entry.row_id" :span="16">
          <span class="text-xs text-red-500">
            {{
              $t('pages.discounts.edit.form.errors.constraints.time_overlap')
            }}
          </span>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <!-- Cancel -->
      <el-button @click="$emit('close')">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Create -->
      <el-button
        v-permissions="{ scopes: ['timetracking:create'] }"
        type="primary"
        @click="submitForm"
      >
        {{ $t('common.interactions.buttons.create') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import pick from 'just-pick'
import flush from 'just-flush'
import isEmpty from 'just-is-empty'
import * as uuid from 'uuid'
import { mapGetters } from 'vuex'

import '@/assets/compiled-icons'
import TimeInput from '@/components/inputs/time-input'
import DatePickerInput from '@/components/inputs/date-picker'
import {
  validateTimeRange,
  findBreakOverlap,
  formToEntries,
  convertEntriesDates
} from '@utils/time-tracking-helper'

const initialTimeEntries = () => ({
  date: '',
  day: {
    location: null,
    started_at: '',
    ended_at: ''
  },
  breaks: []
})

export default {
  components: {
    DatePickerInput,
    TimeInput
  },
  props: {
    locale: {
      type: String,
      default: undefined
    },
    staff: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: initialTimeEntries(),
      dayStart: undefined,
      dayEnd: undefined,
      currentOverlap: null,
      rules: {
        date: [
          {
            required: true,
            message: this.$t(
              'pages.timetracking.report.entries.create.rules.date.required'
            ),
            trigger: 'blur'
          }
        ]
      },
      timeRules: [
        {
          min: 5,
          max: 5,
          message: this.$t('common.forms.rules.hour'),
          trigger: 'blur'
        }
      ],
      timeEndingRules: [
        { validator: this.validateStartBeforeEnd, trigger: 'blur' }
      ],
      // FIXME: validation on deep objects its not working
      timeRequired: [{ validator: this.validateRequired, trigger: 'blur' }],
      entryFields: Object.keys(this.baseTimeEntry())
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    isLastEmpty() {
      const breaks = this.form.breaks || []
      const length = breaks.length
      const last = length ? breaks[length - 1] : undefined
      return last ? !!last.started_at : false
    },
    hasDate() {
      return this.form.date
    },
    hasClockInOut() {
      return (
        !isEmpty(this.form.day.started_at) && !isEmpty(this.form.day.ended_at)
      )
    },
    hasBreak() {
      return (
        this.form.breaks.length > 1 ||
        (this.form.breaks.length === 1 &&
          !isEmpty(this.form.breaks[0].started_at))
      )
    },
    translations() {
      return {
        'break.ended_at': this.$t(
          'pages.timetracking.report.entries.create.rules.break.ended_at.required'
        ),
        'rules.date': this.$t(
          'pages.timetracking.report.entries.create.rules.date.required'
        ),
        'day.ended_at': this.$t(
          'pages.timetracking.report.entries.create.rules.day.ended_at.required'
        ),
        'day.started_at': this.$t(
          'pages.timetracking.report.entries.create.rules.day.started_at.required'
        )
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.validateBreaks()
      }
    }
  },
  mounted() {
    this.addEmptyBreak()
    if (this.currentLocation) {
      this.form.day.location = this.currentLocation
    }
  },
  methods: {
    baseTimeEntry(type = null) {
      return {
        id: null,
        location: this.currentLocation || null,
        started_at: null,
        ended_at: null,
        staff: this.staff,
        row_id: uuid.v4(),
        type
      }
    },
    handleDeleteBreak(index) {
      const filteredBreaks = (this.form.breaks || []).filter(
        (_, i) => i !== index
      )
      this.form.breaks = filteredBreaks
      if (!this.form.breaks.length) this.addEmptyBreak()
      this.validateBreaks()
    },
    addEmptyBreak() {
      this.setNewBreak(this.baseTimeEntry('break'))
    },
    setNewBreak(breakObj) {
      const breaks = this.form.breaks || []
      this.form.breaks = [...breaks, breakObj]
    },
    validateTimes() {
      const entries = formToEntries(this.form, this.staff, this.entryFields)
      for (const entry of entries) {
        if (!validateTimeRange(entry.started_at, entry.ended_at)) {
          return false
        }
      }
      return true
    },
    validate(cb) {
      this.$refs.form.validate((valid) => {
        cb(valid && this.validateTimes() && this.validateBreaks())
      })
    },
    validateBreaks() {
      if (this.validateTimes() && this.hasBreak) {
        const invalidBreak = findBreakOverlap(
          this.form.date,
          this.form.day,
          this.form.breaks
        )
        this.currentOverlap = invalidBreak?.row_id
        return isEmpty(invalidBreak)
      }
      return true
    },
    validateStartBeforeEnd(rule, value, callback) {
      // Validate day time
      if (rule.field.includes('day')) {
        if (
          this.form.day.started_at &&
          !validateTimeRange(this.form.day.started_at, value)
        ) {
          callback(
            new Error(
              this.$t('pages.discounts.edit.form.errors.constraints.time_order')
            )
          )
        }
      } else {
        // Validate break time
        const index = rule.field.split('.')[1]
        // Check the break has an end if it has a start
        if (
          this.form.breaks[index].started_at &&
          !this.form.breaks[index].ended_at
        ) {
          callback(
            new Error(
              this.$t(
                `pages.timetracking.report.entries.create.rules.break.ended_at.required`
              )
            )
          )
        }
        // Validate the time range if there is a break
        if (
          this.form.breaks[index].started_at &&
          !validateTimeRange(this.form.breaks[index].started_at, value)
        ) {
          callback(
            new Error(
              this.$t('pages.discounts.edit.form.errors.constraints.time_order')
            )
          )
        }
      }
      callback()
    },
    validateRequired(rule, value, callback) {
      // Validate day time
      if (!value) {
        callback(new Error(this.translations[rule.field]))
      } else {
        callback()
      }
    },
    async submitForm() {
      const warningMessage = this.$t(
        'common.forms.rules.field_warnings.invalid_inputs.required'
      )

      this.validate((valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: warningMessage
          })
        }

        const date = this.form.date
        const cleanedForm = flush(
          pick(this.form, Object.keys(initialTimeEntries()))
        )

        let entries = convertEntriesDates(
          formToEntries(cleanedForm, this.staff, this.entryFields),
          date
        )
        //Clean entries
        entries = entries.map((element) => {
          delete element.row_id
          return element
        })
        this.$emit('submit', entries)
      })
    }
  }
}
</script>
