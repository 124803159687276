<template>
  <el-form ref="form" :model="form">
    <el-row :gutter="20" class="w-full">
      <!-- Clock In -->
      <el-col :span="8">
        <el-form-item
          prop="day.started_at"
          for="day.started_at"
          :label="$t('pages.timetracking.report.entries.clock_in')"
          :rules="timeRules.concat(timeRequired)"
        >
          <time-input
            id="day.started_at"
            v-model="form.day.started_at"
            hide-warning-icon
          />
        </el-form-item>
      </el-col>

      <!-- Clock Out -->
      <el-col :span="8">
        <el-form-item
          prop="day.ended_at"
          for="day.ended_at"
          :label="$t('pages.timetracking.report.entries.clock_out')"
          :rules="timeRules.concat(timeEndingRules).concat(timeRequired)"
        >
          <time-input
            id="day.ended_at"
            v-model="form.day.ended_at"
            allow-empty
            hide-warning-icon
            :disabled="!form.day.started_at"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <!-- Header -->
    <el-row :gutter="20" class="w-full">
      <el-col :span="8">
        <th-input-title
          :title="$t('pages.timetracking.report.entries.break_start')"
        />
      </el-col>

      <el-col :span="8">
        <th-input-title
          :title="$t('pages.timetracking.report.entries.break_end')"
        />
      </el-col>
    </el-row>

    <!-- Rows -->
    <el-row
      v-for="(entry, index) in form.breaks"
      :key="entry.id"
      class="mb-2 last:mb-0 w-full"
    >
      <el-row :gutter="20" class="w-full">
        <!-- Started at -->
        <el-col :span="8">
          <el-form-item
            :rules="timeRules"
            :prop="`breaks.${index}.started_at`"
            :for="`breaks.${index}.started_at`"
            class="mb-0"
          >
            <time-input
              :id="`breaks.${index}.started_at`"
              v-model="entry.started_at"
              hide-warning-icon
              :disabled="!hasClockInOut"
              :class="{
                'validation-error': currentOverlap === entry.row_id
              }"
            />
          </el-form-item>
        </el-col>

        <!-- Eneded at -->
        <el-col :span="8">
          <el-form-item
            :rules="timeRules.concat(timeEndingRules)"
            :prop="`breaks.${index}.ended_at`"
            :for="`breaks.${index}.ended_at`"
            class="mb-0"
          >
            <time-input
              :id="`breaks.${index}.ended_at`"
              v-model="entry.ended_at"
              :allow-empty="true"
              hide-warning-icon
              :disabled="!entry.started_at || !hasClockInOut"
              :class="{
                'validation-error': currentOverlap === entry.row_id
              }"
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <!-- Delete break -->
          <el-button
            v-permissions="{ scopes: ['timetracking:delete'] }"
            :disabled="!entry.started_at"
            plain
            icon="Delete"
            class="-ml-3 el-button--text-icon"
            @click="handleDeleteBreak(index)"
          />

          <!-- Add break -->
          <el-button
            v-if="index === form.breaks.length - 1"
            data-testid="add-empty-break"
            :disabled="!isLastEmpty"
            icon="Plus"
            plain
            class="el-button--primary-icon"
            @click="addEmptyBreak"
          />
        </el-col>
      </el-row>

      <el-col v-show="currentOverlap === entry.row_id" :span="16">
        <span class="text-xs text-red-500">
          {{ $t('pages.discounts.edit.form.errors.constraints.time_overlap') }}
        </span>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import compare from 'just-compare'
import safeGet from 'just-safe-get'
import isEmpty from 'just-is-empty'
import compareAsc from 'date-fns/compareAsc'
import * as uuid from 'uuid'
import { mapGetters } from 'vuex'

import '@/assets/compiled-icons'
import TimeInput from '@/components/inputs/time-input'
import {
  validateTimeRange,
  findBreakOverlap,
  formToEntries,
  convertEntriesDates
} from '@utils/time-tracking-helper'

const initialTimeEntries = () => ({
  day: {
    started_at: '',
    ended_at: ''
  },
  breaks: []
})

export default {
  components: {
    TimeInput
  },
  props: {
    entries: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String,
      default: undefined
    },
    dayDate: {
      type: String,
      default: ''
    },
    staff: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: initialTimeEntries(),
      dayStart: undefined,
      dayEnd: undefined,
      currentOverlap: null,
      rules: {
        date: [
          {
            required: true,
            message: this.$t(
              'pages.timetracking.report.entries.create.rules.date.required'
            ),
            trigger: 'blur'
          }
        ]
      },
      timeRules: [
        {
          min: 5,
          max: 5,
          message: this.$t('common.forms.rules.hour'),
          trigger: 'blur'
        }
      ],
      timeEndingRules: [
        { validator: this.validateStartBeforeEnd, trigger: 'blur' }
      ],
      // FIXME: validation on deep objects its not working
      timeRequired: [{ validator: this.validateRequired, trigger: 'blur' }],
      entryFields: Object.keys(this.baseTimeEntry())
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    isLastEmpty() {
      const breaks = this.form.breaks || []
      const length = breaks.length
      const last = length ? breaks[length - 1] : undefined
      return last ? !!last.started_at : false
    },
    hasClockInOut() {
      return (
        !isEmpty(this.form.day.started_at) && !isEmpty(this.form.day.ended_at)
      )
    },
    hasBreak() {
      return (
        this.form.breaks.length > 1 ||
        (this.form.breaks.length === 1 &&
          !isEmpty(this.form.breaks[0].started_at))
      )
    },
    translations() {
      return {
        'break.ended_at': this.$t(
          'pages.timetracking.report.entries.create.rules.break.ended_at.required'
        ),
        'rules.date': this.$t(
          'pages.timetracking.report.entries.create.rules.date.required'
        ),
        'day.ended_at': this.$t(
          'pages.timetracking.report.entries.create.rules.day.ended_at.required'
        ),
        'day.started_at': this.$t(
          'pages.timetracking.report.entries.create.rules.day.started_at.required'
        )
      }
    }
  },
  watch: {
    entries: {
      deep: true,
      handler: function (newEntries, oldEntries) {
        if (!compare(newEntries, oldEntries)) {
          this.form = initialTimeEntries()
          this.handleEntries()
        }
      }
    },
    form: {
      deep: true,
      handler: function () {
        this.validateBreaks()
      }
    }
  },
  mounted() {
    this.handleEntries()
  },
  methods: {
    baseTimeEntry(type = null) {
      return {
        id: null,
        started_at: null,
        ended_at: null,
        location: this.currentLocation || null,
        staff: this.staff,
        row_id: uuid.v4(),
        type
      }
    },
    entriesToForm() {
      if (!this.entries.length) return this.addEmptyBreak()

      const sortedEntries = [...this.entries].sort((a, b) =>
        compareAsc(new Date(a.started_at), new Date(b.started_at))
      )

      for (const entry of sortedEntries) {
        const entryObj = {
          ...entry,
          row_id: uuid.v4(),
          started_at: entry.started_at
            ? new Date(entry.started_at)
                .toLocaleTimeString(this.locale, { hour12: false })
                .substring(0, 5)
            : '',
          ended_at: entry.ended_at
            ? new Date(entry.ended_at)
                .toLocaleTimeString(this.locale, { hour12: false })
                .substring(0, 5)
            : ''
        }
        if (entry.type === 'day') {
          this.dayStart = entry.started_at
          this.dayEnd = entry.ended_at
          this.staffId = entry.staff
          this.form.day = entryObj
        } else {
          this.setNewBreak(entryObj)
        }
      }
    },
    handleDeleteBreak(index) {
      const breakId = safeGet(this.form, `breaks.${index}.id`)
      if (breakId) return this.$emit('delete-break', breakId)
      const filteredBreaks = (this.form.breaks || []).filter(
        (_, i) => i !== index
      )
      this.form.breaks = filteredBreaks
      if (!this.form.breaks.length) this.addEmptyBreak()
      this.validateBreaks()
    },
    addEmptyBreak() {
      this.setNewBreak(this.baseTimeEntry('break'))
    },
    setNewBreak(breakObj) {
      const breaks = this.form.breaks || []
      this.form.breaks = [...breaks, breakObj]
    },
    validateTimes() {
      const entries = formToEntries(this.form, this.staff, this.entryFields)
      for (const entry of entries) {
        if (!validateTimeRange(entry.started_at, entry.ended_at)) {
          return false
        }
      }
      return true
    },
    validate(cb) {
      this.$refs.form.validate((valid) => {
        cb(valid && this.validateTimes() && this.validateBreaks())
      })
    },
    validateBreaks() {
      if (this.validateTimes() && this.hasBreak) {
        const invalidBreak = findBreakOverlap(
          new Date(this.dayDate),
          this.form.day,
          this.form.breaks
        )
        this.currentOverlap = invalidBreak?.row_id
        return isEmpty(invalidBreak)
      }
      return true
    },
    validateStartBeforeEnd(rule, value, callback) {
      // Validate day time
      if (rule.field.includes('day')) {
        if (
          this.form.day.started_at &&
          !validateTimeRange(this.form.day.started_at, value)
        ) {
          callback(
            new Error(
              this.$t('pages.discounts.edit.form.errors.constraints.time_order')
            )
          )
        }
      } else {
        // Validate break time
        const index = rule.field.split('.')[1]
        // Check the break has an end if it has a start
        if (
          this.form.breaks[index].started_at &&
          !this.form.breaks[index].ended_at
        ) {
          callback(
            new Error(
              this.$t(
                `pages.timetracking.report.entries.create.rules.break.ended_at.required`
              )
            )
          )
        }
        // Validate the time range if there is a break
        if (
          this.form.breaks[index].started_at &&
          !validateTimeRange(this.form.breaks[index].started_at, value)
        ) {
          callback(
            new Error(
              this.$t('pages.discounts.edit.form.errors.constraints.time_order')
            )
          )
        }
      }
      callback()
    },
    validateRequired(rule, value, callback) {
      // Validate day time
      if (!value) {
        callback(new Error(this.translations[rule.field]))
      } else {
        callback()
      }
    },
    async submitForm() {
      const warningMessage = this.$t(
        'common.forms.rules.field_warnings.invalid_inputs.required'
      )

      this.validate((valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: warningMessage
          })
        } else {
          let entries = convertEntriesDates(
            formToEntries(this.form, this.staff, this.entryFields),
            this.dayStart
          )
          //Clean entries
          entries = entries.map((element) => {
            delete element.row_id
            return element
          })
          this.$emit('submit', entries)
        }
      })
    },
    handleEntries() {
      if (this.entries.length) {
        this.entriesToForm()
        if ((this.form.breaks || []).length === 0) this.addEmptyBreak()
      }
    }
  }
}
</script>
