<template>
  <th-modal
    name="timetrack-entry-details"
    width="800px"
    :title="
      $t('pages.timetracking.report.entries.timesheet.title') + ' - ' + dayDate
    "
    @close="close"
  >
    <!-- Edit -->
    <edit-form
      v-if="isEdit"
      ref="entry-form"
      :entries="entryDetails"
      :locale="locale"
      :day-date="dayEntry.started_at"
      :staff="staff"
      @delete-break="(id) => $emit('delete-break', id)"
      @submit="(entries) => $emit('submit', entries)"
    />

    <!-- Preview -->
    <div v-else>
      <el-row :gutter="20">
        <!-- Clock in -->
        <el-col :span="8">
          <th-input-title
            :title="$t('pages.timetracking.report.entries.clock_in')"
          />
          <div class="text-lg">
            {{ getFormattedTime(dayEntry.started_at) || '–' }}
          </div>
        </el-col>

        <!-- Clock out -->
        <el-col :span="8">
          <th-input-title
            :title="$t('pages.timetracking.report.entries.clock_out')"
          />
          <div class="text-lg">
            {{ getFormattedTime(dayEntry.ended_at) || '–' }}
          </div>
        </el-col>
      </el-row>

      <hr class="my-4" />

      <!-- Breaks -->
      <div>
        <!-- Breaks head -->
        <el-row :gutter="20">
          <el-col :span="8">
            <th-input-title
              :title="$t('pages.timetracking.report.entries.break_start')"
            />
          </el-col>
          <el-col :span="8">
            <th-input-title
              :title="$t('pages.timetracking.report.entries.break_end')"
            />
          </el-col>
          <el-col :span="8">
            <th-input-title :title="$t('common.titles.type')" />
          </el-col>
        </el-row>

        <!-- Breaks row -->
        <el-row
          v-for="entry in otherEntries"
          :key="entry.id"
          class="pb-1 text-lg last:pb-0"
          :gutter="20"
        >
          <el-col :span="8">
            <div>{{ getFormattedTime(entry.started_at) || '–' }}</div>
          </el-col>
          <el-col :span="8">
            <div>{{ getFormattedTime(entry.ended_at) || '–' }}</div>
          </el-col>
          <el-col :span="8">
            <div v-text="$t('pages.timetracking.report.entries.types.break')" />
          </el-col>
        </el-row>
      </div>
    </div>

    <template #footer>
      <!-- Cancel -->
      <el-button plain @click="close">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Save -->
      <el-button
        v-if="isEdit"
        v-permissions="{ scopes: ['timetracking:update'] }"
        type="primary"
        @click="handleSave"
      >
        {{ $t('common.interactions.buttons.save') }}
      </el-button>

      <!-- Edit -->
      <el-button v-else type="primary" @click="isEdit = true">
        {{ $t('common.interactions.buttons.edit') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import EditForm from './entry-edit-form'
import timeMixin from '@/views/timetracking/mixin/time-mixin'

export default {
  components: {
    EditForm
  },
  props: {
    entryDetails: {
      type: Array,
      default: () => []
    },
    staff: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { locale, getFormattedTime } = timeMixin()
    return {
      locale,
      getFormattedTime
    }
  },
  data() {
    return {
      isEdit: false
    }
  },
  computed: {
    dayEntry() {
      return this.entryDetails.find((entry) => entry.type === 'day') || {}
    },
    otherEntries() {
      return this.entryDetails.filter((entry) => entry.type !== 'day') || []
    },
    dayDate() {
      if (!this.dayEntry.started_at) return '--'
      return this.$date.formatDateWithTimezone(
        this.dayEntry.started_at,
        this.dayEntry.timezone
      )
    }
  },
  methods: {
    handleSave() {
      this.$refs['entry-form'].submitForm()
    },
    close() {
      this.isEdit = false
      this.$emit('close')
    }
  }
}
</script>
