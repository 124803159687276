<template>
  <div v-show="dropdownOpen" class="th-container" :style="containerStyle">
    <div v-for="filter in filters" :key="filter.name" class="mb-4">
      <th-input-title :title="filter.label" :title-for="filter.label" />
      <el-select
        :id="filter.label"
        v-cancel-read-only
        :model-value="getFilterProp(filter.name, 'value')"
        class="select w-full"
        :locale="locale"
        filterable
        clearable
        :popper-append-to-body="false"
        :disabled="getFilterProp(filter.name, 'disabled')"
        @change="handleInput(filter.name, $event)"
        @keyup.enter="handleEnter"
        @clear="handleClear(filter.name)"
      >
        <el-option
          v-for="item in filter.options"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
    </div>

    <div class="mt-6 select-none button-box flex justify-end w-full">
      <el-button
        @click="reset"
        v-text="$t('common.interactions.buttons.reset')"
      />
      <el-button
        type="primary"
        :disabled="searchDisabled"
        @click="handleSearchClick"
        v-text="$t('common.interactions.buttons.search')"
      />
    </div>
  </div>
</template>

<script>
import safeSet from 'just-safe-set'
export default {
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    filtersObject: {
      type: Object,
      default: () => ({})
    },
    locale: {
      type: String,
      default: 'de-DE'
    },
    handleChildInput: {
      type: Function,
      default: () => {}
    },
    handleEnter: {
      type: Function,
      default: () => {}
    },
    reset: {
      type: Function,
      default: () => {}
    },
    handleSearchClick: {
      type: Function,
      default: () => {}
    },
    searchDisabled: {
      type: Boolean,
      default: false
    },
    dropdownOpen: {
      type: Boolean,
      default: false
    },
    containerStyle: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getFilterProp(field, prop) {
      return this.filtersObject[field]?.[prop] ?? undefined
    },
    handleClear(filterName) {
      safeSet(this.filtersObject, filterName, {
        ...this.filtersObject[filterName],
        value: null
      })
      this.handleEnter()
    },
    handleInput(field, value) {
      this.handleChildInput({
        ...this.filtersObject,
        [field]: {
          ...this.filtersObject.field,
          value
        }
      })
    }
  }
}
</script>

<style scoped>
.th-container {
  width: 280px;
  border: 1px lightgrey solid;
  border-top: 0;
  padding: 29px;
  margin-top: 1px;
  transform: translate(0, -1px);
  position: absolute;
  z-index: 10;
  background: white;
}

.th-container .select :deep(.el-input) {
  width: inherit;
}
</style>
