<template>
  <div class="relative flex flex-col w-full h-full">
    <header v-if="Object.keys(staff).length" class="mb-6">
      <div class="text-xl mb-6 font-semibold" v-text="fullName" />
      <div class="flex flex-row items-center justify-between">
        <time-period
          v-model="dateRange"
          @update:modelValue="persistFilterDateRange"
        />

        <div class="flex-grow" />

        <!-- Create -->
        <el-button
          type="primary"
          plain
          class="ml-2"
          icon="Plus"
          @click="$thModal.show('timetrack-entry-create')"
        >
          {{ $t('common.interactions.buttons.create') }}
        </el-button>
      </div>
    </header>

    <!-- No data -->
    <div
      v-if="!Object.keys(staff).length"
      class="h-full flex flex-col justify-center items-center text-xl"
    >
      <img :src="timetrackingImage" width="500" alt="timetracking-empty" />
      <div
        class="py-6"
        v-text="$t('pages.timetracking.report.empty.description')"
      />
    </div>

    <!-- Table -->
    <template v-else>
      <el-table
        ref="tableData"
        v-loading="loading"
        row-key="id"
        :data="timeEntries"
        :headers="headers"
        class="w-full shadow overflow-hidden bg-white"
        height="100%"
        :empty-text="$t('common.data.no_data')"
        row-class-name="cursor-pointer"
        @row-click="openEditModal"
      >
        >
        <el-table-column
          v-for="header in headers"
          :key="header.name"
          :prop="header.name"
          :label="header.label"
          :formatter="header.formatter"
          :align="header.align"
          :header-align="header.align || 'left'"
          :show-overflow-tooltip="header.truncate"
          :min-width="header.minWidth"
        />
      </el-table>

      <footer class="flex border-t pt-4">
        <div class="pr-4">
          <span
            class="pr-2 font-semibold"
            v-text="`${$t('pages.timetracking.report.total_break_overall')}:`"
          />
          <span>{{ totalBreakOverall || '—' }}</span>
        </div>
        <div>
          <span
            class="pr-2 font-semibold"
            v-text="`${$t('pages.timetracking.report.total_worked_overall')}:`"
          />
          <span>{{ totalWorkedOverall || '—' }}</span>
        </div>
      </footer>
    </template>

    <!-- Details -->
    <entry-details
      :entry-details="entryDetails"
      :staff="staff.id"
      @close="$thModal.hide('timetrack-entry-details')"
      @delete-break="handleDeleteBreak"
      @submit="handleSubmit('timetrack-entry-details', $event)"
    />

    <!-- Create -->
    <entry-create
      :locale="locale"
      :staff="staff.id"
      @close="$thModal.hide('timetrack-entry-create')"
      @submit="handleSubmit('timetrack-entry-create', $event)"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import debounce from 'debounce'
import compare from 'just-compare'
import isEmpty from 'just-is-empty'
import fullName from '@/utils/full-name'
import * as uuid from 'uuid'
import EntryDetails from './timetrack-entry-details'
import EntryCreate from './entry-create-form'
import timeMixin from '@/views/timetracking/mixin/time-mixin'
import {
  getTimeDiffText,
  isDateRangeInsideDateRange
} from '@utils/time-tracking-helper'
import timetrackingImage from '@/assets/illustrations/timetracking.svg'
import TimePeriod from './time-period'
import { mapGetters } from 'vuex'
export default {
  components: {
    EntryDetails,
    EntryCreate,
    TimePeriod
  },
  props: {
    staff: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const { headers, locale, getTotalOverallTime } = timeMixin()
    return {
      headers,
      locale,
      getTotalOverallTime
    }
  },
  data() {
    return {
      client_id: uuid.v4(),
      loading: false,
      timeEntries: [],
      entryDetails: [],
      selectedRow: null,
      entryDetailsArray: [],
      timetrackingImage
    }
  },
  computed: {
    ...mapGetters({
      dateRange: 'Config/getFilterDateRange'
    }),
    fullName() {
      return fullName(this.staff)
    },
    //getTotalOverallTime defined in Mixin
    totalBreakOverall() {
      const overallBreaks = this.timeEntries?.map((entry) => entry.total_break)
      return overallBreaks ? this.getTotalOverallTime(overallBreaks) : null
    },
    totalWorkedOverall() {
      const overallWorked = this.timeEntries?.map((entry) => entry.total_worked)
      return overallWorked ? this.getTotalOverallTime(overallWorked) : null
    },
    timePeriodRange() {
      const start = this.dateRange.start
        ? new Date(this.dateRange.start).toLocaleDateString(this.locale)
        : undefined
      const end = this.dateRange.end
        ? new Date(this.dateRange.end).toLocaleDateString(this.locale)
        : undefined
      return start && end ? `${start} - ${end}` : ''
    }
  },
  watch: {
    staff(newStaff, oldStaff) {
      if (!compare(newStaff, oldStaff)) {
        this.fetchReport()
      }
    },
    dateRange(newDate, oldDate) {
      if (!compare(newDate, oldDate)) {
        this.fetchReport()
      }
    }
  },
  mounted() {
    if (this.staff) {
      this.fetchReport()
    }
  },
  methods: {
    getEntries(id) {
      return this.entryDetailsArray.find((element) => element.id === id)
        ?.entries
    },
    persistFilterDateRange(filter) {
      this.$store.dispatch('Config/setFilterDateRange', filter)
    },
    async openEditModal(row) {
      await this.loadEntries(row)
      this.entryDetails = this.getEntries(row.id)
      this.$thModal.show('timetrack-entry-details')
    },
    async fetchEntryDetails(date) {
      const errorMessage = this.$t('common.error.action.read.multiple', {
        resources: this.$t('common.resource.timetracking.plural')
      })
      const query = { query: { date, deleted: false } }
      try {
        const { data } = await th
          .timetracking()
          .getEntries(this.staff.id, query)
        return data
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
        return []
      }
    },
    async loadEntries(row) {
      this.selectedRow = row
      const date = this.$date.formatDate(row.date, 'yyyy-MM-dd')
      if (isEmpty(this.getEntries(row.id))) {
        const entries = (await this.fetchEntryDetails(date))
          .map((element) => {
            element.total_break = getTimeDiffText(
              element.started_at,
              element.ended_at,
              {
                hour: this.$t('common.hour.min'),
                minute: this.$t('common.minute.min')
              }
            )
            return element
          })
          .filter((element) => this.entryIsForRow(element, row))
        const newEntry = {
          id: row.id,
          entries
        }
        this.entryDetailsArray.push(newEntry)
      }
    },
    entryIsForRow(entry, row) {
      if (!row || !row.clock_in) return false
      const clockIn = new Date(row.clock_in)
      const clockOut = new Date(row.clock_out)
      // Check by id if its the entry of the day
      // Check if the breaks are inside the day entry
      const entryClockIn = new Date(entry.started_at)
      const entryClockOut = new Date(entry.ended_at)
      return (
        entry.id === row.id ||
        !row.clock_out ||
        isDateRangeInsideDateRange(
          clockIn,
          clockOut,
          entryClockIn,
          entryClockOut
        )
      )
    },
    fetchReport: debounce(async function fetchReport() {
      if (!this.staff || !this.staff.id) return
      const { start, end } = this.dateRange || {}
      const query = { start, end, deleted: false }
      try {
        this.loading = true
        const { data } = await th.timetracking().get(this.staff.id, { query })
        this.timeEntries = data
        this.entryDetailsArray = []
      } catch (err) {
        const errorMessage = this.$t('common.error.action.read.single', {
          resource: this.$t('common.resource.timetracking_report.singular')
        })
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    }, 250),
    async handleSubmit(formToHide, entries) {
      const inst = th.timetracking()
      let isNew = true
      const resource = this.$t('common.resource.timetracking.singular')
      for (const entry of entries) {
        const validEntry = { ...entry, client_id: this.client_id }
        const { id, ...restEntry } = validEntry

        try {
          if (!id) {
            await inst.createEntry(restEntry)
          } else {
            if (isNew) isNew = false
            await inst.updateEntry(id, restEntry)
          }
        } catch (err) {
          const errorMessage = id
            ? this.$t('common.error.action.create.single', { resource })
            : this.$t('common.error.action.update.single', { resource })
          this.$logException(err, {
            trackError: false,
            message: errorMessage
          })
          return
        }
      }

      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })
      this.$message({
        type: 'success',
        message: successMessage
      })
      await this.fetchReport()
      this.$thModal.hide(formToHide)
    },
    async handleDeleteBreak(breakId) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.timetracking.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.timetracking.singular')
      })

      const entryDate =
        this.entryDetails.length &&
        (this.entryDetails[0].started_at || this.entryDetails[0].ended_at)
      const validDate = this.$date.formatDate(entryDate, 'yyyy-MM-dd')
      if (!breakId || !validDate) {
        return this.$logException(new Error(errorMessage), {
          trackError: false,
          message: errorMessage
        })
      }
      try {
        await th.timetracking().deleteEntry(breakId)
        this.entryDetails = await this.fetchEntryDetails(validDate)
        this.entryDetails = this.entryDetails.filter((element) =>
          this.entryIsForRow(element, this.selectedRow)
        )
        this.fetchReport()
        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    }
  }
}
</script>
