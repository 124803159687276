<template>
  <th-page-wrapper>
    <div class="p-8 h-full">
      <div class="tab-content flex">
        <div class="flex flex-col mr-6">
          <staff-list v-model="selectedStaff" />
        </div>
        <report-table :staff="selectedStaff" />
      </div>
    </div>
  </th-page-wrapper>
</template>

<script>
import ReportTable from './components/report-table'
import StaffList from './components/staff-list'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.timetracking.title')
    }
  },
  components: {
    ReportTable,
    StaffList
  },
  data() {
    return {
      selectedStaff: undefined
    }
  }
}
</script>

<style scoped>
.tab-content {
  height: 99%;
}
</style>
