<template>
  <search-input
    v-bind="$attrs"
    @submit="(filters) => $emit('submit', filters)"
    @reset="$emit('reset')"
  >
    <template #filter-dropdown="slotProps">
      <filter-dropdown
        :dropdown-open="slotProps.dropdownOpen"
        :filters="slotProps.filters"
        :filters-object="slotProps.filtersObject"
        :locale="slotProps.locale"
        :handle-child-input="slotProps.handleChildInput"
        :handle-enter="slotProps.handleEnter"
        :reset="slotProps.reset"
        :handle-search-click="slotProps.handleSearchClick"
        :search-disabled="slotProps.searchDisabled"
        :container-style="slotProps.containerStyle"
      />
    </template>
  </search-input>
</template>

<script>
import SearchInput from './search-input'
import FilterDropdown from './filter-dropdown'

export default {
  components: {
    SearchInput,
    FilterDropdown
  }
}
</script>
