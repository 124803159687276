<template>
  <div
    ref="filter-container"
    v-click-outside="closeOnOutsideClick"
    :style="containerStyle"
  >
    <el-input
      :id="id"
      ref="el-input"
      v-model="filtersObject[textFieldName].value"
      class="search-input-container"
      :placeholder="
        inputPlaceholder || $t('common.interactions.buttons.search')
      "
      data-lpignore="true"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      :clearable="clearable"
      :class="{
        open: dropdownOpen,
        'text-input-disabled': allowTextInput === false
      }"
      @input="onFreetextInput"
      @clear="handleClear"
      @keyup.enter="handleEnter"
    >
      <template #append>
        <div
          class="flex justify-center items-center h-full w-full cursor-pointer"
          data-testid="filter-open"
          @click="toggleDropdown"
        >
          <div class="relative h-full w-full flex justify-center items-center">
            <svgicon
              :src="require(`@/assets/icons/th-icon-filter.svg`)"
              :style="{
                height: '20px',
                width: '20px',
                fill: `${isFiltersOn ? '#9B9B9B' : '#299CDF'}`
              }"
            />
            <el-icon
              v-if="isFiltersOn"
              class="filter-on absolute text-xs bg-white rounded-full"
            >
              <SuccessFilled
            /></el-icon>
          </div>
        </div>
      </template>
    </el-input>
    <slot
      name="filter-dropdown"
      :dropdown-open="dropdownOpen"
      :filters="filters"
      :filters-object="filtersObject"
      :locale="locale"
      :handle-child-input="handleChildInput"
      :handle-enter="handleEnter"
      :reset="reset"
      :handle-search-click="handleSearchClick"
      :search-disabled="searchDisabled"
      :handle-initial-fetch="handleInitialFetch"
      :container-style="containerStyle"
    />
  </div>
</template>

<script>
import flush from 'just-flush'
import typeOf from 'just-typeof'
import compare from 'just-compare'
import mapValues from 'just-map-values'

export default {
  props: {
    locale: {
      type: String,
      default: 'en'
    },
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    },
    filters: {
      type: Array,
      required: true
    },
    textFieldName: {
      type: String,
      required: false,
      default: 'search'
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    searchButtonText: {
      type: String,
      default: ''
    },
    resetButtonText: {
      type: String,
      default: ''
    },
    submitOnEnter: {
      type: Boolean,
      required: false,
      default: false
    },
    submitOnItem: {
      type: Boolean,
      required: false,
      default: false
    },
    submitOnItemRemove: {
      type: Boolean,
      required: false,
      default: false
    },
    submitOnReset: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnSubmit: {
      type: Boolean,
      required: false,
      default: true
    },
    allowTextInput: {
      type: Boolean,
      required: false,
      default: true
    },
    clearable: {
      type: Boolean,
      required: false,
      default: undefined
    },
    logger: {
      type: Function,
      required: false,
      default: (message) => {}
    },
    width: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      input: '',
      dropdownOpen: false,
      filtersObject: this.normaliseIncomingFilters()
    }
  },
  computed: {
    isFiltersOn() {
      return !!Object.keys(flush(this.modelValue)).length
    },
    searchDisabled() {
      return !Object.keys(this.filtersObject).length
    },
    containerStyle() {
      if (this.width && this.width > 350) {
        return { width: `${this.width}px` }
      }
      return {}
    }
  },
  watch: {
    filtersObject: {
      deep: true,
      handler: function (newValues) {
        this.logger(
          'vue-search-filter: triggered filtersObject watcher',
          newValues
        )
        this.$emit('update:modelValue', newValues)
      }
    },
    modelValue: {
      deep: true,
      handler: function (newValues, oldValue) {
        if (Object.keys(newValues).length || !compare(newValues, oldValue)) {
          this.filtersObject = this.normaliseIncomingFilters()
        }
      }
    }
  },
  methods: {
    normaliseIncomingFilters() {
      const incomingFilters = {
        ...this.modelValue
      }

      this.filters.forEach((item) => {
        // REVIEW: watch for unwanted overrides
        incomingFilters[item.name] = {
          ...item,
          value: incomingFilters[item.name] || item.value || null
        }
      })

      const result = {
        ...incomingFilters
      }

      if (!result[this.textFieldName]) {
        result[this.textFieldName] = { label: null, value: null }
      } else if (typeOf(result[this.textFieldName]) === 'string') {
        result[this.textFieldName] = {
          label: null,
          value: result[this.textFieldName]
        }
      }

      return result
    },
    dispatchFilter() {
      const filter = {}
      Object.entries(this.filtersObject)
        .filter((item) => {
          return !(
            item[1].value === null ||
            item[1].value === '' ||
            this.isEmptyArray(item[1].value)
          )
        })
        .forEach((item) => {
          filter[item[0]] = item[1].value
        })

      this.$emit('filter', filter, this.filtersObject)

      if (this.submitOnItem) {
        this.handleSubmit()
        this.logger('vue-search-filter: submitted after handling enter')
      }
    },
    onFreetextInput(v) {
      if (v === '') this.filtersObject[this.textFieldName].value = null
      this.dispatchFilter()
    },
    handleChildInput(v) {
      this.filtersObject = v
      this.dispatchFilter()
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
      if (this.dropdownOpen === false) this.$emit('close-dropdown')
      this.$emit('toggled-dropdown')

      this.logger('vue-search-filter: requested dropwdown toggle')
    },
    handleSubmit() {
      if (this.closeOnSubmit) this.closeDropdown()

      const trimmedFiltersObject = this.preparefiltersObject(this.filtersObject)

      this.$emit('submit', trimmedFiltersObject)
      return trimmedFiltersObject
    },
    preparefiltersObject(filtersObject) {
      const trimmed = { ...filtersObject }

      // remove trailing whitespace in all nested levels
      function trimInputs(obj) {
        for (var key in obj) {
          if (obj[key] !== null && typeof obj[key] === 'object') {
            // works on objects and arrays
            obj[key] = trimInputs(obj[key])
          }

          if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim()
          }
        }
        return obj
      }

      trimInputs(trimmed)

      return trimmed
    },
    handleSearchClick() {
      this.handleSubmit()
      this.logger('vue-search-filter: handled search click')
    },
    handleClear() {
      this.reset()
    },
    reset() {
      this.filtersObject = this.normaliseIncomingFilters()
      this.filtersObject = mapValues(this.filtersObject, (value, key) => {
        if (typeOf(value) === 'object') {
          return { ...value, value: null }
        }
        return null
      })

      this.$emit('reset')

      this.logger('vue-search-filter: reset')

      if (this.submitOnReset === true) {
        this.$emit('submit', this.handleSubmit())
        this.logger('vue-search-filter: submitted on reset')
      }
    },
    openDropdown() {
      this.$emit('open-dropdown')
      this.dropdownOpen = true
      this.$emit('opened-dropdown')

      this.logger('vue-search-filter: opened dropdown')
    },
    closeDropdown() {
      this.dropdownOpen = false
      this.$emit('close-dropdown')

      this.logger('vue-search-filter: requested dropdown close')
    },

    handleEnter() {
      this.logger('vue-search-filter: handled enter')

      if (this.submitOnEnter) {
        this.handleSubmit()
        this.logger('vue-search-filter: submitted after handling enter')
      }
    },

    closeOnOutsideClick(e) {
      if (this.dropdownOpen) {
        this.closeDropdown()
        this.logger('vue-search-filter: closed dropdown')
      }
    },
    isEmptyArray(value) {
      return Array.isArray(value) && !value.length
    },
    handleInitialFetch(filter, data) {
      this.filtersObject[filter.name].originalData = data
    }
  }
}
</script>

<style scoped>
.el-input :deep(.el-input__inner) {
  height: 40px;
}

.el-input :deep(.el-input__inner:hover) {
  border-color: rgb(220, 223, 230);
}

.el-input.open :deep(.el-input__inner) {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.el-input.no-left-border :deep(.el-input__inner) {
  border-left: 0;
}

.el-input :deep(.el-input__inner:focus) {
  border-color: rgb(220, 223, 230);
}

.el-input :deep(.el-input__suffix) {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.el-input.text-input-disabled :deep(input) {
  pointer-events: none;
}

.el-input :deep(.el-input-group__append) {
  background: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 38px;
  height: 40px;
  line-height: 38px;
}

.filter-on {
  top: 6px;
  right: 5px;
  color: #299cdf;
}
</style>
