/* eslint-disable */
/*require('./flags')
require('./th-barcode-scan')
require('./th-help')
require('./th-icon-accounting')
require('./th-icon-bag')
require('./th-icon-barcode')
require('./th-icon-bell')
require('./th-icon-calendar-day')
require('./th-icon-calendar-month')
require('./th-icon-calendar-week')
require('./th-icon-chart')
require('./th-icon-chevron-down')
require('./th-icon-close')
require('./th-icon-collapse')
require('./th-icon-customers')
require('./th-icon-delete')
require('./th-icon-download')
require('./th-icon-enter')
require('./th-icon-equlizer')
require('./th-icon-error')
require('./th-icon-eye')
require('./th-icon-filter')
require('./th-icon-help-transparent')
require('./th-icon-home')
require('./th-icon-info-bg-white')
require('./th-icon-info-outline')
require('./th-icon-inventory')
require('./th-icon-logout')
require('./th-icon-loyalty')
require('./th-icon-magnifying-glass')
require('./th-icon-money-bill')
require('./th-icon-person')
require('./th-icon-plus')
require('./th-icon-product-link')
require('./th-icon-product-standard')
require('./th-icon-product-variant')
require('./th-icon-products')
require('./th-icon-reports')
require('./th-icon-resources')
require('./th-icon-restore')
require('./th-icon-settings')
require('./th-icon-shopping-cart')
require('./th-icon-staff')
require('./th-icon-suppliers')
require('./th-icon-timetracking')
require('./th-icon-trash')
require('./th-icon-upload')
require('./th-icon-utilities')
require('./th-icon-warning-outline')
require('./th-icon-warning')
require('./th-logo')
require('./th-news')
require('./th-search')
require('./th-table-plus')
require('./th-tick')
require('./th-logo')
*/