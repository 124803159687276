<template>
  <div v-loading="loading" class="h-full flex flex-col min-h-0">
    <search-filter
      id="search-filter-input"
      class="w-full mb-4"
      :filters="filters"
      :model-value="parsedFilters"
      close-on-submit
      text-field-name="q"
      submit-on-enter
      submit-on-reset
      clearable
      @submit="handleSubmit"
      @reset="resetFilters"
    />

    <div
      class="h-full flex flex-col overflow-y-auto border-t border-gray-200 bg-white rounded shadow"
    >
      <div
        v-if="!loading && !orderedList.length"
        class="h-full flex justify-center items-center"
      >
        {{ $t('common.data.no_data') }}
      </div>
      <dynamic-scroller
        v-else-if="!loading && orderedList.length"
        class="h-full"
        :items="orderedList"
        :min-item-size="35"
        key-field="id"
      >
        <template #default="{ item: staff, active }">
          <dynamic-scroller-item
            :item="staff"
            :active="active"
            :size-dependencies="[
              staff.firstname,
              staff.middlename,
              staff.lastname
            ]"
            :data-index="staff.id"
          >
            <div
              :class="[
                'p-4 border-b border-gray-200 text-sm cursor-pointer outline-none hover:bg-blue-100',
                {
                  'bg-blue-100 text-gray-900 focus:bg-blue-100 focus:text-gray-900':
                    staff.id === modelValue.id
                }
              ]"
              :tabindex="0"
              @click="$emit('update:modelValue', staff)"
            >
              {{ fullName(staff) || '–' }}
            </div>
          </dynamic-scroller-item>
        </template>
      </dynamic-scroller>
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import flush from 'just-flush'
import { DynamicScroller, DynamicScrollerItem } from 'vue3-virtual-scroller'
import { mapGetters } from 'vuex'

import fullName from '@/utils/full-name'
import SearchFilter from './search-filter/index'

export default {
  components: {
    SearchFilter,
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      resources: {},
      parsedFilters: {},
      staffList: [],
      next: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    orderedList() {
      const localStaffList =
        Array.isArray(this.staffList) && Object.keys(this.parsedFilters).length
          ? this.staffList
          : undefined
      const list = localStaffList || this.staffList || []
      return this.orderStaffList(list)
    },
    filters() {
      return [
        {
          name: 'staff_groups',
          type: 'select',
          resource: 'staffGroups',
          label: this.$t('pages.timetracking.staff_list.filters.staff_groups'),
          filterable: true,
          options:
            this.resources.staffGroups &&
            this.resources.staffGroups.map((group) => ({
              value: group.id,
              label: group.name,
              key: group.id
            }))
        },
        {
          name: 'branch_group',
          type: 'select',
          resource: 'branchGroups',
          label: this.$t('pages.timetracking.staff_list.filters.branch_groups'),
          filterable: true,
          disabled: !!this.currentLocation,
          options:
            this.resources.branchGroups &&
            this.resources.branchGroups.map((group) => ({
              value: group.id,
              label: group.name,
              key: group.id
            }))
        }
      ]
    }
  },
  watch: {
    currentLocation(newLocation, oldLocation) {
      if (newLocation !== oldLocation) {
        this.updateFilters(this.parsedFilters)
      }
    }
  },
  async mounted() {
    this.fetchResources()
    this.updateFilters(this.parsedFilters)
  },
  methods: {
    async handleSubmit(filters) {
      const filterQueries = {}

      for (const currentFilter of Object.entries(filters)) {
        const [key, valueObj] = currentFilter
        const filterValue =
          safeGet(valueObj, 'originalData.queryInput') ||
          valueObj?.value ||
          null
        filterQueries[key] = filterValue
      }

      if (this.currentLocation) filterQueries.branch = this.currentLocation
      this.parsedFilters = flush(filterQueries)
      await this.fetch(this.parsedFilters)
    },
    fullName(staff) {
      return fullName(staff)
    },
    async fetch(filters = {}) {
      try {
        this.loading = true

        const { staff } = await this.$resourceFetch({
          resource: 'staff',
          query: filters
        })

        this.staffList = staff
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    orderStaffList(staffList) {
      const staffByKey = {}

      for (const item of staffList) {
        const uniqueKey = `${fullName(item)}${item.id}`
        staffByKey[uniqueKey] = item
      }

      const orderedList = Object.keys(staffByKey)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => staffByKey[key])

      return orderedList
    },
    async fetchResources() {
      try {
        this.loading = true

        this.resources = await this.$resourceFetch(
          'staffGroups',
          'branchGroups'
        )
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    async resetFilters() {
      this.updateFilters({})
    },
    updateFilters(filters) {
      this.parsedFilters = flush({
        ...filters,
        branch: this.currentLocation
      })
      this.fetch(this.parsedFilters)
    }
  }
}
</script>

<style scoped>
.th-box-shadow {
  box-shadow: 2px 2px 4px 1px lightgray;
}
</style>
